import React, { useEffect } from 'react'
import { Divider, Grid, Avatar, List, Button, Toast, Dialog, SpinLoading } from 'antd-mobile'

import EditDrawer from './EditDrawer'
import { uploadFileApi } from '@/services/base'
import { checkCanUploadFileApi } from '@/services/chat'

export default function UserAvatar(props) {
  const { avatar, onChange, isLoading } = props

  const [value, setValue] = React.useState(null)
  const [loadingVisible, setLoadingVisible] = React.useState(false)

  const [open, setOpen] = React.useState(false)
  const attachRef = React.createRef()

  const avatarOption1 = [...new Array(30).keys()]

  const avatarOption2 = [...new Array(30).keys()]

  const avatarOption3 = [...new Array(12).keys()]

  const handleSave = async () => {
    const { code } = await onChange({ avatar: value })
    if (code === 200) {
      setOpen(false)
    }
  }

  const handleSelect = (item) => {
    return () => {
      setValue(item)
    }
  }

  useEffect(() => {
    setValue(avatar)
  }, [open])

  // useEffect(() => {
  //   if (open) {
  //     setTimeout(() => {
  //       console.log(attachRef)
  //       if (attachRef.current) {
  //         attachRef.current.addEventListener('click', function (e) {
  //           e.stopPropagation()
  //         })
  //       }
  //     }, 300)
  //   }
  // }, [attachRef, attachRef.current])

  async function uploadFile() {
    const resp = await checkCanUploadFileApi(null, 'avatar')
    if (resp.code !== 200) {
      Toast.show({ icon: 'fail', content: resp.msg })
      return
    }
    const { files } = attachRef.current
    if (files.length === 0) return
    const tmpFile = files[0]
    const fileSize = tmpFile.size / 1024 / 1024
    const supportFileType = ['image/jpeg', 'image/png', 'image/gif']
    if (supportFileType.indexOf(tmpFile.type) === -1) {
      Toast.show({ icon: 'fail', content: '不支持该文件类型' })
      return
    }
    if (fileSize > 10) {
      Toast.show({ icon: 'fail', content: '图片大小不能超过10MB' })
      return
    }
    setLoadingVisible(true)
    const { code, data, msg } = await uploadFileApi('avatar', tmpFile)
    setLoadingVisible(false)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    setValue(data.url)
  }

  async function handleUploadImage() {
    attachRef.current.click()
  }

  return (
    <>
      <Dialog
        visible={loadingVisible}
        content={
          <div style={{ textAlign: 'center' }}>
            <SpinLoading style={{ '--size': '60px', margin: '0 auto', marginBottom: '20px' }} />
            {loadingVisible && '上传中'}
          </div>
        }
        closeOnMaskClick
        onClose={() => {
          setLoadingVisible(false)
        }}
        bodyStyle={{ opacity: 0.9 }}
      />

      <List.Item
        style={{ textDecoration: 'none', lineHeight: '55px' }}
        onClick={() => setOpen(true)}
        extra={<Avatar src={avatar} style={{ width: 50, height: 50 }} />}>
        <span style={{ color: 'var(--theme-text-color)' }}>头像</span>
      </List.Item>

      <EditDrawer
        onClickSave={handleSave}
        title="设置头像"
        isLoading={isLoading}
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        height="100%">
        <div style={{ padding: '0px 40px 0px 40px' }}>
          <div style={{ position: 'relative', width: '100%', paddingTop: '100%', height: '0' }}>
            <Avatar src={value} style={{ top: '0', position: 'absolute', width: '100%', height: '100%' }} />
          </div>
        </div>

        <Grid columns={3} style={{ textAlign: 'center', marginTop: '10px', padding: '0 10px' }}>
          <Grid.Item key="a1">
            <span style={{ fontSize: '10px', color: 'red' }}>请上传裁剪好的方形图，头像将经过人工审核</span>
          </Grid.Item>
          <Grid.Item key="a2">
            <div onClick={handleUploadImage}>
              <Button style={{ width: '106px' }} color="primary">
                上传头像
              </Button>
              <input style={{ display: 'none' }} ref={attachRef} type="file" onChange={uploadFile} />
            </div>
          </Grid.Item>
          <Grid.Item key="a3">
            <span style={{ fontSize: '10px', color: 'red' }}>违规内容一次扣50魅力值，严重者将被封禁</span>
          </Grid.Item>
        </Grid>

        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <Divider style={{ marginBottom: '10px' }}>可选头像</Divider>
          <Grid columns={6} gap={10} style={{ padding: '10px' }}>
            {avatarOption1.map((item, index) => {
              item = `/avatar-option/male${index + 1}.jpg`
              return (
                <Grid.Item key={index} onClick={handleSelect(item)}>
                  <Avatar src={item} style={{ width: '50px', height: '50px' }} />
                </Grid.Item>
              )
            })}
          </Grid>
          <Divider style={{ margin: '5px 0px' }} />
          <Grid columns={6} gap={10} style={{ padding: '10px' }}>
            {avatarOption2.map((item, index) => {
              item = `/avatar-option/female${index + 1}.jpg`
              return (
                <Grid.Item key={index} onClick={handleSelect(item)}>
                  <Avatar src={item} style={{ width: '50px', height: '50px' }} />
                </Grid.Item>
              )
            })}
          </Grid>
          <Divider style={{ margin: '5px 0px' }} />
          <Grid columns={6} gap={10} style={{ padding: '10px' }}>
            {avatarOption3.map((item, index) => {
              item = `/avatar-option/common${index + 1}.jpg`
              return (
                <Grid.Item key={index} onClick={handleSelect(item)}>
                  <Avatar src={item} style={{ width: '50px', height: '50px' }} />
                </Grid.Item>
              )
            })}
          </Grid>
        </div>
      </EditDrawer>
    </>
  )
}
